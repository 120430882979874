:root {
  --primaryColor: #5890b4;
  --primaryHover: #497896;
  --secHoverColor: #253b53;
  --greyColor: #f7f7f7;
}

.header {
  position: fixed;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
  padding: 1.5rem;
  background: var(--primaryColor);
  z-index: 2000;


  .logo-container {
    width: 130px;
    cursor: pointer;
  }

  @media screen and (max-width: 768px) {
    .menuDiv {
      background: var(--primaryColor);
      position: absolute;
      height: max-content;
      width: 80%;
      border-radius: 10px;
      padding: 1rem;
      top: -700%;
      left: 50%;
      transform: translate(-50%);
      box-shadow: 0 2px 4px rgba(243, 243, 243, 0.904);
      transition: .3s ease;
      z-index: 3000;

      .menuLists {
        display: flex;
        flex-direction: column;
        justify-content: center;
        width: 100%;
        align-items: center;
        margin: auto;
        padding: 1rem 0;

        .navItem {
          padding: 1rem 0;

          .menuLink {
            color: white;
            font-size: 15px;
            font-weight: 500;

            &:hover {
              color: var(--secHoverColor);
            }
          }
        }
      }

      .closeNavbar {
        color: white;
        position: absolute;
        top: 1rem;
        right: 1.5rem;

        .icon {
          font-size: 25px;
        }

        &:hover .icon {
          color: var(--secHoverColor);
          background-color: transparent;
        }
      }
    }

    .activeNavbar {
      top: 6rem;
    }

    .socialIcons {
      display: none;
    }
  }

  .toggleNavbar {
    color: var(--greyColor);
    font-size: 25px;

    &:hover {
      .icon {
        color: var(--secHoverColor);
      }
    }
  }
}

// MEDIA QUERIES
@media screen and (min-width: 769px) {
  .menuDiv {
    .menuLists {
      display: flex;
      flex-direction: row;
      padding: 0 .5rem;

      .navItem {
        padding: 0 .5rem;

        .menuLink {
          color: white;
          font-size: 14px;

          &:hover {
            color: var(--secHoverColor);
          }
        }
      }
    }
  }

  .closeNavbar, .toggleNavbar {
    display: none;
  }

  .socialIcons {
    gap: .5rem;

    .icon {
      color: white;
    }
  }
}

@media screen and (min-width: 1240px) {
  .header {
    padding: 2.5rem 15rem;

    .menuLists {
      padding: 0 1rem;

      .navItem {
        padding: 0 1rem;

        .menuLink {
          color: white;
          font-size: 16px;
        }
      }
    }

    .socialIcons {
      .icon {
        font-size: 22px;
      }
    }
  }
}
