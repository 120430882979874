:root{
  --primaryColor: #5890b4;
  --primaryHover: #497896;
  --secHoverColor: #253b53;
  --greyColor: #f7f7f7;
}

.auth{

  .authContainer{
    margin: auto;
    width: 100%;

    .single{
      position: relative;
      background: white;
      box-shadow: 0 3px 5px rgb(218,218,218);
      padding: 10%;
      text-align: center;
      border-radius: 10px;
      transition: .4s ease;

      &:hover{
        transform: translateY(-10px);
      }

      .fields input {
        border: none;
        outline: none;
        background: none;
        font-size: 14px;
        color: #555;
        width: 80%;
        padding: 12px 12px 15px 5px;
      }

      &:hover{
        box-shadow: 12px 12px 12px #cbced1, 12px 12px 12px #fff;
      }

      .e-mailadres, .password {
        margin-bottom: 20px;
        border-radius: 10px;
        box-shadow: 8px 8px 8px #cbced1, 8px 8px 8px #fff;

        &:hover{
          box-shadow: 1px 1px 1px #cbced1, 1px 1px 1px #fff;
        }
      }

      .icons {
        margin: 1px 5px -5px -15px;
        color: var(--primaryColor);
      }

      .btn {
        position: relative;
        font-size: 1.1rem;
        font-weight: 600;
        padding: 10px 10px 10px 5px;
        box-shadow: 12px 12px 12px #cbced1, 12px 12px 12px #fff;
        width: 100%;
        margin-top: 5%;
        margin-bottom: 5%;
        border: 2px solid var(--primaryColor);
        background: var(--primaryColor);
        color: white;
        text-decoration: none;
        letter-spacing: 1px;
        transition: 0.5s;
        overflow: hidden;
        border-radius: 5px;
        cursor: pointer;
      }

      .btn:hover {
        background: var(--primaryHover);
        box-shadow: 1px 1px 1px #cbced1, 1px 1px 1px #fff;
      }

      .btn::before {
        content: " ";
        position: absolute;
        top: 0;
        left: -100%;
        width: 100%;
        height: 100%;
        background: linear-gradient(90deg, transparent, #fff, transparent);
        transition: 0.5s;
      }

      .btn:hover::before {
        left: 100%;
      }
    }

    .p {
      font-size: 14px;
    }

      .a{
        color: var(--primaryColor);
      }
    }
  }


// MEDIA QUERIES
@media screen and (min-width: 574px ){
  .auth{
    .authContainer{
      width: 40%;

    }
  }
}

@media screen and (min-width: 840px ){
  .auth{
    .authContainer {
      width: 40%;
    }
  }
}
