@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@300;400;500;800&display=swap');

*{
    padding: 0;
    margin: 0;
    box-sizing: border-box;
    font-family: 'Montserrat', sans-serif;
}

:root{
    --primaryColor: #5890b4;
    --primaryHover: #497896;
    --secHoverColor: #253b53;
    --greyColor: #f7f7f7;
}

/* Default css */
html{
    scroll-behavior: smooth;
}

a{
    color: var(--primaryColor);
    text-decoration: none;
}
a:hover{
    color: #253b53;
}

li{
    list-style: none;
}

span{
    display: block;
}

.flex{
    display: flex;
    align-items: center;
}

.grid{
    display: grid;
    align-items: center;
    gap: 1rem;
}

input{
    font-size: 100%;
}

.spanText{
    font-size: 14px;
}

p{
    font-size: 13px;
    /*opacity: .7;*/
    margin-bottom: 10px;
}

h2{
    font-size: 18px;
    font-weight: 500;
    color: #5890b4;
    margin-bottom: 4px;
}

h6 {
    font-size: 14px;
    font-weight: 500;
    color: #ea1f44;
}

.secTitle{
    text-align: center;
    padding: 1rem 0;
    font-size: 25px;
    font-weight: 600;
    color: var(--primaryColor);
    margin-top: 80px;
    margin-bottom: 10px;
}

.section{
    padding: 4rem 0 2rem;
}

.container{
    padding-left: 1.5rem;
    padding-right: 1.5rem;
}

img{
    height: auto;
    width: 100%;
}

video{
    margin-top: 40px;
}

.icon{
    font-size: 14px;
    cursor: pointer;
}

.btn{
    border: 2px solid var(--primaryColor);
    padding: 8px 8px 8px 5px;
    font-size: 14px;
    cursor: pointer;
    font-weight: 600;
    position: relative;
    color: var(--primaryColor);
    background: transparent;
    border-radius: 5px;
}

/* Media queries */
@media screen and (min-width: 960px) {
    .container{
        width: 75%;
        margin: auto;
    }

    .section{
        padding: 6rem 0 4rem;
    }

    .secTitle{
        font-size: 30px;
        margin-top: 100px;
    }
}

::selection{
    background: var(--secHoverColor);
    color: white;
}

::-webkit-scrollbar{
    width: 13px;
    background: var(--primaryHover);
}

::-webkit-scrollbar-thumb{
    background: var(--primaryColor);
    border-radius: 10px;
}



