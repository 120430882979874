:root{
  --primaryColor: #5890b4;
  --primaryHover: #497896;
  --secHoverColor: #253b53;
  --greyColor: #f7f7f7;
}

.nieuwsbrief{
  width: 100%;

  .secContainer{
    height: 400px;
    width: 100%;
    position: relative;
    display: flex;
    align-items: center;
    justify-content: center;

    video{
      height: 100%;
      width: 100%;
      object-fit: cover;
    }

    .textDiv{
      position: absolute;
      display: grid;
      padding: 2rem;
      color: white;
      text-align: center;
      gap: 1rem;

      .title{
        font-size: 18px;
        font-weight: 700;
      }
      p{
        opacity: 1;
      }

      .input_btn{
        flex-direction: column;
        gap: 1rem;

        input{
          background: #c3fff95f;
          padding: .8rem;
          border: none;
          outline: none;
          color: white;
          font-size: 13px;
          width: 95%;
          border-radius: 5px;

          &::placeholder{
            color: white;
            font-size: 12px;
          }
        }

        //.btn{
        //  background: var(--primaryColor);
        //  border: none;
        //  font-size: 15px;
        //  padding: 1rem 2rem;
        //  width: 95%;
        //  color: white;
        //  transition: .3s ease;
        //
        //  &:hover{
        //    background: var(--primaryHover);
        //  }
        //}

        .btn {
          position: relative;
          font-size: 15px;
          font-weight: 600;
          padding: 1rem 2rem;
          width: 95%;
          transition: .3s ease;
          //margin-top: 5%;
          //margin-bottom: 5%;
          border: none;
          background: var(--primaryColor);
          color: white;
          text-decoration: none;
          letter-spacing: 1px;
          overflow: hidden;
          border-radius: 5px;
          cursor: pointer;
        }

        .btn:hover {
          background: var(--primaryHover);
          //box-shadow: 1px 1px 1px #cbced1, 1px 1px 1px #fff;
        }

        .btn::before {
          content: " ";
          position: absolute;
          top: 0;
          left: -100%;
          width: 100%;
          height: 100%;
          background: linear-gradient(90deg, transparent, #fff, transparent);
          transition: 0.5s;
        }

        .btn:hover::before {
          left: 100%;
        }
      }
    }
  }
}


//media queries
@media screen and (min-width: 520px) {
  .nieuwsbrief{
    .secContainer{
      .textDiv{
        gap: 2rem;

        .title{
          font-size: 25px;
        }

        p{
          max-width: 500px;
          padding: 0 3rem;
        }

        .input_btn{
          .btn{
            font-size: 16px;
          }
        }
      }
    }
  }
}

@media screen and (min-width: 1024px){
  .nieuwsbrief{
    .secContainer{
      .textDiv{
        gap: 2rem;
        .title{
          font-size: 30px;
        }
      }
    }
  }
}
