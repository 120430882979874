:root {
  --primaryColor: #5890b4;
  --primaryHover: #497896;
  --secHoverColor: #253b53;
  --greyColor: #f7f7f7;
}

.loadingPage {
  display: flex;
  flex-direction: column;
  gap: 1rem;
  align-items: center;
  justify-content: center;
  position: absolute;
  top: 0;
  left: 0;
  background: linear-gradient(to right, #5890b4, #497896, #253b53);
  height: 100%;
  width: 100%;
  z-index: 999;
}

.logo {
  width: 150px;
  stroke: white;
  fill-opacity: 0;
  stroke-width: 3px;
  stroke-dasharray: 4500;
  animation: draw 8s ease;
}

p {
  font-size: 14px;
}

.slogan-container {
  color: white;
  font-size: 14px;
  //letter-spacing: 5px;
  //text-transform: uppercase;
}


