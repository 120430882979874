:root {
  --primaryColor: #3ab3a7;
  --primaryHover: #0c988a;
  --secHoverColor: #253b53;
  --greyColor: #f7f7f7;
}

.staff .staffContainer {
  margin-top: 4rem;
  gap: 4rem;
}
.staff .staffContainer .singleStaff {
  position: relative;
  background: white;
  box-shadow: 0 3px 5px rgb(218, 218, 218);
  padding: 1.5rem;
  text-align: center;
  border-radius: 10px;
  transition: 0.4s ease;
}
.staff .staffContainer .singleStaff:hover {
  transform: translateY(-10px);
}
.staff .staffContainer .singleStaff .imgDiv {
  position: absolute;
  height: 80px;
  width: 80px;
  right: 50%;
  left: 50%;
  top: -23%;
  border-radius: 50%;
  overflow: hidden;
  transform: translateX(-50%);
  border: 10px solid white;
}
.staff .staffContainer .singleStaff p {
  padding: 1rem;
  line-height: 1.3rem;
}
.staff .staffContainer .singleStaff .name {
  color: var(--primaryColor);
  font-weight: 600;
  padding-top: 2rem;
  font-size: 14px;
}
.staff .staffContainer .singleStaff .jobTitle {
  color: var(--secHoverColor);
  font-size: 12px;
  padding: 0.5rem;
  font-weight: 500;
}
.staff .staffContainer .singleStaff .contact {
  color: var(--primaryColor);
  font-size: 14px;
  font-weight: 500;
}

@media screen and (min-width: 574px) {
  .staff .staffContainer {
    grid-template-columns: repeat(2, 1fr);
  }
}
@media screen and (min-width: 840px) {
  .staff .staffContainer {
    grid-template-columns: repeat(3, 1fr);
  }
}/*# sourceMappingURL=Staff.css.map */